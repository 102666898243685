import { navToggle } from './header.js';
import { contactMap, contactSubmit } from './contact.js';
import { clickTips } from './tips.js';
import { facilityTop, facilityCaro, facilityMapToggle, facilityMap, facilityFilter } from './facility.js';
import { initReviewsCaro } from './reviews.js';
import { homeMap } from './home.js';
import { initVideosCaro } from './videosCaro.js';

navToggle.init();

if (document.getElementById('contact_form')) {
	contactSubmit();
}

if(document.querySelector('#facility_top_toggle')) {
	facilityTop();
}

if(document.querySelector('#facility_caro')) {
	facilityCaro();
}

if(document.querySelector('#facility_map_toggle')) {
	facilityMapToggle();
}

if(document.querySelector('#facility_size_filters')) {
	facilityFilter();
}

if(document.querySelector('#reviews_caro')) {
	initReviewsCaro();
}

if(document.querySelector('#facility_map')) {
	facilityMap.init();
}

if (document.getElementById('storage_tips')) {
	clickTips();
}

if(document.querySelector('#contact_map')) {
	contactMap.init();
}

if(document.querySelector('#home_map')) {
	homeMap.init();
}

if(document.querySelector('#facility_unit_videos')) {
	initVideosCaro();
}