/* eslint-disable no-undef */

import { AutomatitCarousel } from './automatit_carousel.m';

import {
	getMapsHelper
} from './util/mapsHelper';

export const facilityTop = () => {
	const mobileToggle = document.querySelector('#facility_top_toggle');
	const info = document.querySelector('#facility_top');
	function mobileClick(){
		info.classList.toggle('active');
		mobileToggle.classList.toggle('active');
	}
	mobileToggle.addEventListener('click', () => mobileClick());
};

export const facilityCaro = () => {
	AutomatitCarousel({
		element: document.querySelector('#facility_caro'),
		showDots: true,
		imagesAsBackgrounds: true,
		images: window.facCaroImgs
	});
};

export const facilityMapToggle = () => {
	const caroMap = document.getElementById('facility_map');
	document.getElementById('facility_map_toggle').addEventListener('click', e => {
		const clickedToggle = e.target.closest('img');
		clickedToggle.classList.toggle('active');
		caroMap.classList.toggle('active');
	});
};

export const facilityMap = (function(){
	let my = {};

	my.init = function(){
		if(document.getElementById('facility_map')){

			const mapHelper = getMapsHelper();

			mapHelper.ready()
				.then(() => {
					const theMap = mapHelper.createMap({
						element: document.getElementById('facility_map'),
						locationElementSelector: '#facility_map',
						useRichmarker: true,
						markerReducer: el => {
							return {
								lat: el.getAttribute('lat'),
								lng: el.getAttribute('lng'),
								content: `
                        <span class="map_pin"><img src="/dist/images/icons/map_pin_ic.svg" alt=""></span>
                        `
							};
						}
					});

					google.maps.event.addListenerOnce(theMap, 'idle', () => {
						theMap.setZoom(15);
					});
				});

		}
	};

	return my;
})();

export const facilityFilter = () => {
	const filters = document.querySelectorAll('#facility_size_filters .filter');
	const rows = document.querySelectorAll('.unit_row');
	const noUnits = document.querySelector('#no_units');
	document.getElementById('facility_size_filters').addEventListener('click', e => {
		const clickedFilter = e.target.closest('button');
		var unitShown = 0;
		if(clickedFilter) {
			console.log("Clicked");
			filters.forEach(i => {
				i.classList.remove('active');
			});
			if(clickedFilter.classList.contains('filter')) {
				clickedFilter.classList.toggle('active');
			}
			rows.forEach(i => {
				i.setAttribute('hide', true);
				if((i.getAttribute('size') === clickedFilter.getAttribute('size') || clickedFilter.getAttribute('size') === 'all')) {
					unitShown++;
					i.removeAttribute('hide');
				}
			});
			if(unitShown === 0){
				noUnits.style.display = 'block';
			}else {
				noUnits.style.display = 'none';
			}
		}
	});
};
