export const navToggle = (function(){
	let my = {};

	function _mobileToggle() {
		document.getElementById('toggle_mobile_nav').addEventListener('click', function(e){
			let btn = e.currentTarget;
			let nav = document.getElementById('mobile_nav_container');
			
			if(btn.classList.contains('open')) {
				btn.classList.remove('open');
				nav.classList.remove('open');
			} else {
				btn.classList.add('open');
				nav.classList.add('open');
			}
		});
	}

	my.init = function(){
		_mobileToggle();
	};

	return my;
})();