import { AutomatitCarousel } from './automatit_carousel.m';

const videosCaro = {
	caro: document.querySelector('#videos_caro'),
	init: function() {
		window.videosCaro = AutomatitCarousel({
			element: this.caro,
			intervalTiming: false,
			useChevrons: false
		});
	}
};
export const initVideosCaro = () => videosCaro.init();