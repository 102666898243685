/* eslint-disable no-undef */

import { getMapsHelper } from './util/mapsHelper';

export const homeMap = (function(){
	let my = {};

	my.init = function(){
		if(document.getElementById('home_map')){

			const mapHelper = getMapsHelper();

			mapHelper.ready()
				.then(() => {
					const theMap = mapHelper.createMap({
						element: document.getElementById('home_map'),
						locationElementSelector: '#home_map',
						useRichmarker: true,
						markerReducer: el => {
							return {
								lat: el.getAttribute('lat'),
								lng: el.getAttribute('lng'),
								content: `
                        <span class="map_pin"><img src="/dist/images/icons/map_pin_ic.svg" alt=""></span>
                        `
							};
						}
					});

					google.maps.event.addListenerOnce(theMap, 'idle', () => {
						theMap.setZoom(15);
					});
				});

		}
	};

	return my;
})();