export const clickTips = () => {

	document.querySelector('#storage_tips').addEventListener('click', function(e) {
		if(e.target && e.target.nodeName === "H3") {
			let el = e.target;
			if(el.hasAttribute('data-active')) {
				el.removeAttribute('data-active');
			}
			else {
				el.setAttribute('data-active','');
			}
		}
	});

};



